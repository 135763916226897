import React from 'react'
import ReactDOM from 'react-dom'

function VrHuntViewer (props) {
    const completedHandler = (stats, results) => {
        if (stats) {
            stats = JSON.parse(JSON.stringify(stats))
            stats.status = stats.status === 'true'
        }

        let newResults = []
        if (typeof results !== "undefined") {
            results.getArray().forEach(result => {
                if (result._type === 'clue') {
                    newResults.push(
                        {
                            'type': result._type,
                            'name': result.name,
                            'value': result.value === 'true',
                        }
                    )
                } else if (result._type === 'question') {
                    newResults.push(
                        {
                            'type': result._type,
                            'name': result.name,
                            'value': result.value === 'true',
                            'answer': result.answer || null,
                            'correct_answer': result.correct_answer,
                        }
                    )
                }
            })
        }
        axios.put(`/visit/${props.visitId}/done`, {
            stats: stats,
            results: newResults,
        })
            .then(response => {
                if (response.data.redirect) {
                    setTimeout(function () {
                        window.location.href = response.data.redirect
                    }, response.data.timeout);
                }
            })
            .catch(error => {
                console.log(error)
            })
    }

    React.useEffect(() => {
        window.completedHandler = completedHandler
        embedpano({
            xml: props.xmlPath,
            target: 'viewerElement',
            html5: 'always',
            mobilescale: 1.0,
            passQueryParameters: {},
            vars: {}
        })
    }, [completedHandler])
    return null
}

export default VrHuntViewer

if (document.getElementById('viewerElement')) {
    const propsContainer = document.getElementById("viewerElement");
    const props = Object.assign({}, propsContainer.dataset);
    delete propsContainer.dataset.visitId
    delete propsContainer.dataset.xmlPath
    ReactDOM.render(<VrHuntViewer {...props} />, propsContainer)
}
