import CodeMirror from 'codemirror/lib/codemirror.js';

document.addEventListener('DOMContentLoaded', function () {
    let pageEditor = document.querySelector('textarea[name=pageEditor]', {});
    if (pageEditor) {
        let editor = CodeMirror.fromTextArea(pageEditor);
        editor.on('change', editor => {
                pageEditor.value = editor.getValue();
            });
        editor.setOption('theme', 'darcula')
        editor.setSize("100%", "100%");
    }
})
