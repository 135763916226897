import React from 'react'
import ReactDOM from 'react-dom'

import Chart from 'chart.js/auto'

const DATASETS_COLORS = {
    won: '#62f32d',
    lost: '#EC1B2E',
}

const LABELS = {
    won: 'Won',
    lost: 'Lost',
}

export default class ScoresActivity extends React.Component {
    constructor (props) {
        super(props)

        this.graph = React.createRef()
    }

    unCamelCase (str) {
        const result = str.replace(/([A-Z])/g, ' $1')
        return result.charAt(0).toUpperCase() + result.slice(1)
    }

    componentDidMount () {
        const dataSets = []

        for (const label in this.props.datasets) {
            if (!Object.prototype.hasOwnProperty.call(this.props.datasets, label)) continue
            dataSets.push({
                label: LABELS[label] || this.unCamelCase(label),
                backgroundColor: DATASETS_COLORS[label] || 'pink',
                borderColor: DATASETS_COLORS[label] || 'pink',
                borderWidth: 3,
                pointRadius: 2,
                pointHoverRadius: 4,
                data: this.props.datasets[label],
                fill: false,
            })
        }

        const data = {
            labels: this.props.labels.map(label => label),
            datasets: dataSets
        }

        const options = {
            elements: {
                line: {
                    tension: 0.3
                }
            },
            spanGaps: true,
            legend: {
                display: true,
                labels: {
                    usePointStyle: true
                }
            },
            scales: {
                xAxes: [{
                    display: true
                }],
                yAxes: [{
                    display: true
                }]
            }
        }

        const ctx = this.graph.current.getContext('2d')
        const myBarChart = new Chart(ctx, {
            type: 'line',
            data: data,
            options: options
        })
    }

    render () {
        return <canvas className="sh-barGraph" ref={this.graph}/>
    }
}

if (document.getElementById('scoresActivity')) {
    const propsContainer = document.getElementById("scoresActivity");
    const props = Object.assign({}, propsContainer.dataset);
    props.campaignActivity = JSON.parse(props.campaignActivity)
    delete propsContainer.dataset.campaignActivity
    ReactDOM.render(<ScoresActivity {...props.campaignActivity}/>, propsContainer)
}
