import flatpickr from 'flatpickr'

document.addEventListener('DOMContentLoaded', function () {
    let dateFrom = document.querySelector('input[name=scheduled_from]')
    let dateTo = document.querySelector('input[name=scheduled_to]')

    let config = {
        altInput: true,
        altFormat: "F j, Y",
        dateFormat: "d.m.Y",
    }

    if (dateFrom) {
        flatpickr(dateFrom, config)
    }
    if (dateTo) {
        flatpickr(dateTo, config)
    }
})
